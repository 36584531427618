import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import NotFoundPage from './routes/404'
import Login from './routes/login'
import MSLoginPage from './routes/mslogin'
import NavLayout from './routes/NavLayout'
import Profile from './routes/profile'
import Register from './routes/register'
import ResetPassword from './routes/reset-password'
import Root from './routes/Root'
import theme from './util/theme'
import ErrorPage from './routes/Error'
import AutoLogin from './routes/auto-login'

import AppleLoginPage from './routes/applelogin'
import AdminAccessPage from './routes/admin/access'
import PlatformAccessPage from './routes/platform/[id]/index'
import PlatformPage from './routes/platform'
import PlatformUsagePage from './routes/platform/[id]/usage'
import PlatformBillingPage from './routes/platform/[id]/billing'
import PlatformDashboard from './routes/platform/[id]/dashboard'
import PlatformCustomerTemplatesPage from './routes/platform/[id]/templates/[customerIdentifier]'
import PlatformTemplatePage from './routes/platform/[id]/templates/template/[templateIdentifier]'
import CustomerPage from './routes/customer'
import ConfigurePage from './routes/platform/[id]/configure'
import CustomerTemplatePage from './routes/customer/template/[id]'
import DocsPage from './routes/docs'
import DocsLayout from './routes/docs/components/DocsLayout'
import MDPages from './routes/docs/pages'

const router = createBrowserRouter([
  {
    errorElement: <ErrorPage />,
    path: '/customer',
    element: <CustomerPage />,
  },
  {
    errorElement: <ErrorPage />,
    path: '/customer/template/:id',
    element: <CustomerTemplatePage />,
  },
  {
    errorElement: <ErrorPage />,
    element: <DocsLayout />,
    children: [
      {
        path: '/docs',
        element: <DocsPage />,
      },
      {
        path: '/docs/*',
        element: <MDPages />,
      },
    ],
  },
  {
    errorElement: <ErrorPage />,
    element: <NavLayout />,
    children: [
      {
        path: '/platform',
        element: <PlatformPage />,
      },
      {
        path: '/platform/:id',
        element: <PlatformAccessPage />,
      },
      {
        path: '/platform/:id/usage',
        element: <PlatformUsagePage />,
      },
      {
        path: '/platform/:id/billing',
        element: <PlatformBillingPage />,
      },
      {
        path: '/platform/:id/configure',
        element: <ConfigurePage />,
      },
      {
        path: '/platform/:id/dashboard',
        element: <PlatformDashboard />,
      },
      {
        path: '/platform/:id/templates/:customerIdentifier',
        element: <PlatformCustomerTemplatesPage />,
      },
      {
        path: '/platform/:id/templates/template/:templateIdentifier',
        element: <PlatformTemplatePage />,
      },
      {
        path: '/admin/access',
        element: <AdminAccessPage />,
      },
      {
        path: '/applelogin',
        element: <AppleLoginPage />,
      },
      {
        path: '/auto-login',
        element: <AutoLogin />,
      },
      {
        path: '/',
        element: <Root />,
      },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/register',
        element: <Register />,
      },
      {
        path: '/profile',
        element: <Profile />,
      },

      {
        path: '/reset-password',
        element: <ResetPassword />,
      },
      {
        path: '/mslogin',
        element: <MSLoginPage />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ].map((p) => ({
      ...p,
      errorElement: <ErrorPage />,
    })),
  },
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
