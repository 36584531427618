import { RKey } from '@counsel-project/counsel-keys-api'
import DeleteIcon from '@mui/icons-material/DeleteRounded'
import LockIcon from '@mui/icons-material/LockRounded'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import StyledTableCell from './StyledTableCell'
import StyledTableRow from './StyledTableRow'

type KeyNameChipProps = {
  name: string
}

const KeyNameChip = ({ name }: KeyNameChipProps) => {
  return (
    <Chip icon={<LockIcon />} label={name} color="default" size="small" sx={{ py: 2, px: 1 }} />
  )
}

export type KeyRowProps = {
  isMobile?: boolean
  data: RKey
  onClick?: (data: RKey) => void
  onClickDelete?: (data: RKey) => void
}

const KeyRow = ({ data, onClick, onClickDelete }: KeyRowProps) => {
  const { name } = data

  return (
    <StyledTableRow onClick={() => onClick?.(data)}>
      <StyledTableCell
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: 400,
        }}
      >
        <Typography
          variant="subtitle2"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          <KeyNameChip name={name} />
        </Typography>
      </StyledTableCell>
      <StyledTableCell align="right">
        <IconButton onClick={() => onClickDelete?.(data)}>
          <DeleteIcon />
        </IconButton>
      </StyledTableCell>
    </StyledTableRow>
  )
}

export default KeyRow
